<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <i class="bi bi-robot display-6 text-primary"></i>
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    {{ title }}
                  </h4>
                  <small class="d-block font-size-13 text-muted fw-normal"
                    >Bots Status</small
                  >
                </div>
              </div>
              <div class="ms-auto">
                
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
               <!-- datatable -->
                <div class="col-12 mb-2 d-flex">
                  <div>
                    <label class="d-inline-flex align-items-center">
                      Filter:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Keyword..."
                        class="form-control form-control-sm ms-2"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="ms-auto mb-0">
                    <div class="form-check form-switch form-check-custom mb-0 me-lg-1 form-control-sm">
                  <input class="form-check-input mb-0" type="checkbox" id="liveUpdate" v-model="liveUpdate" @change="updateLiveUpdates">

                  <label class="form-check-label mb-0 font-size-14" for="liveUpdate"> Live 
                    <span class="d-inline-block text-center text-nowrap" style="width: 20px;" v-if="liveUpdate">({{liveUpdateSeconds}}s)</span>
                    <span v-else class="d-inline-block text-center">(-)</span>
                  </label>
                </div>
                  </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="table-responsive mb-0">
                      <!-- striped -->
                        <b-table
                          class="text-nowrap align-middle mb-0 custom-datatable-table"
                          :items="bankAccountList"
                          striped
                          :fields="visibleFields()"
                          responsive="sm"
                          :per-page="perPage2"
                          :current-page="currentPage2"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          @filtered="onFiltered"
                          :busy="loading"
                          show-empty
                        >        
                        <template>
                          <div class="text-center py-5 text-center text-muted" slot="table-busy">
                            <div
                              class="spinner-border text-secondary my-2"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                            <br />
                            <div>Loading...</div>
                          </div>
                        </template>
                        <template slot="empty">
                          <div class="text-center text-muted py-5">
                            <i class="bi bi-robot me-1"></i> No Data Available
                          </div>
                        </template>
                        <template slot="emptyfiltered">
                          <div class="text-center text-muted py-5">
                            <i class="bi bi-robot me-1"></i> There are no records matching your keyword
                          </div>
                        </template>
                        <!-- <template #cell(index)="data">
                          {{ data.index + 1 }}
                          Whole Index
                          {{ currentPage2 * perPage2 - perPage2 + data.index }}
                        </template>

                        <template #cell(name)="data">
                          {{data.value}}
                        </template> -->

                        <template #cell(bot_id)="data">
                          <span v-if="data.value"> #{{data.value || '-'}}</span>
                          <span v-else>-</span>
                        </template>

                        <template #cell(bot_status)="data">
                          <span v-if="data.item.bot_status=='unavailable'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; Offline</span>
                          <span v-else-if="data.item.bot_status=='available'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; Online</span>
                          <span v-else>-</span>

                        
                        </template>

                        <template #cell(bot_last_online)="data">
                          {{data.value || '-'}}
                        </template>

                      

                        <template #cell(upi_accounts)="data">
                          <b-dropdown variant="light" size="sm" :disabled="!data.item.upi_accounts.length">
                              <template v-slot:button-content>
                                <span class="text-primary fw-medium font-size-13">
                                  UPI Accounts ({{data.item.upi_accounts.length}})
                                <i class="mdi mdi-chevron-down"></i>
                                </span>
                                
                              </template>
                              <b-dropdown-item
                                class="d-flex align-items-center font-size-13"
                                href="javascript:void(0)"
                                v-for="(v, i) in data.item.upi_accounts"
                                :key="i"
                                > 
                                <i class="bi bi-check-circle text-primary me-2"></i>
                                  <span> 
                                    {{ v.upi_id }}
                                  </span>
                                </b-dropdown-item
                              >
                            </b-dropdown>
                        </template>
                        
                      

                        <template #cell(active)="data">
                          <span v-if="data.item.active=='0'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; Inactive</span>
                            <span v-else-if="data.item.active=='1'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; Active</span>
                        </template>


                        <template #cell(action)="data">
                            <div v-if="create_modify_permission">
                              <!-- :title="data.item.action === 'available' ? 'Disable Bot' : 'Enable Bot'" -->
                              <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14"
                              v-b-tooltip.hover 
                                v-if="data.item.action!==''">
                                <input class="form-check-input" type="checkbox" role="switch" v-model="data.item.action" true-value="available" false-value="unavailable" :disabled="loading || modalLoading" @change="updateBotStatus(data.item)">
                              </div>
                            </div>
                        </template>

                        <template #cell(bank_name)="data">
                          {{ data.item.bank_name  }} 
                        </template>

                        <template #cell(id)="data">
                          #{{ data.item.id }}
                        </template>
                      </b-table>
                    </div>
                    
                </div>
                <div class="col-12 mb-0" v-if="bankAccountList.length && !loading">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          size="sm"
                          v-model="currentPage2"
                          :total-rows="totalData"
                          :per-page="perPage2"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- datatable end -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie
      :path="''"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import {
  required,
  //minLength,
  //sameAs,
  //helpers
} from "vuelidate/lib/validators";
//const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
/**
 * Bots
 */
const PageTitle = "Bots";

export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  beforeRouteLeave(to, from, next) {
   console.log(to, from, next)
   this.reset();
   next();
  },
  data() {
    return {
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      showLottie: false,
      title: "",
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: "",
          active: true,
        },
      ],
      account_type: "",
      create_modify_permission: false,
      loading: false,
      disabled: false,
      modalSubmit: false,
      modalLoading: false,
      returnData: [],
      selectedBankAccount:[],
      bankAccountList:[],
      liveUpdateIntervalId: null,
      liveUpdate:false,
      liveUpdateLoading:false,
      liveUpdateSeconds: 15,
      liveUpdateSecondsId: null,
      //datatable
      totalData:0,
      totalRows: 1,
      currentPage2: 1,
      perPage2: 30,
      filter: "",
      filterOn: [],
      sortBy: "bot_status",
      sortDesc: false,
      fields: [
       
       
       
       
        // {
        //   key: "bank_account_name",
        //   label:"Bank Acc. Name",
        //   thClass:"user-select-none",
        //   tdClass: "align-middle",
        //   sortable: true,
        // },
        // {
        //   key: "current_bank_balance",
        //   label:"Bank Balance",
        //   thClass:"user-select-none",
        //   tdClass: "align-middle",
        //   formatter: (value) => {
        //     if (value) {
        //       return this.convertCurrencyFormat(value,false);
        //     } else {
        //       return 0; // or any default value if upi_accounts is not an array
        //     }
        //   },
        //   sortable: true,
        // },
       
        {
          key: "bot_id",
          label:"Bot ID",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "bot_token",
          label:"Bot Token",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "account_id",
          label:"Account ID",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "bot_status",
          label:"Bot Status",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "bot_last_online",
          label:"Bot Last Online",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        //{
        //   key: "action",
        //   label:"Bot Action",
        //   thClass:"user-select-none",
        //   tdClass: "align-middle text-center",
        //   sortable: false,
        // }
      ]
    };
  },
  validations: {
    formData: {
      name: {
        required,
      },
    },
  },
  middleware: "authentication",
  async mounted() {
    this.title = PageTitle;
    this.items[1].text = PageTitle;
    await this.reload();
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    this.loading = true
    this.getBankAccountList();
    this.liveUpdate = true
    this.liveUpdateSeconds = 15
    this.liveUpdates();
  },
  created() {},
  methods: {
    async getBankAccountList(){
      this.bankAccountList = []
      console.log("getBankAccountList")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "99999");
      bodyFormData.append("keyword", "");
      bodyFormData.append("sortBy", "status_asc");
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/bankAccount/getAllBotList',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          var resData = response.data;
          if (resData.status == 200) {
            this.totalData = resData.total;
            this.selectedBankAccount = []
            this.bankAccountList = []

            this.liveUpdateSeconds = 15
            resData.data.forEach(element => {
              this.bankAccountList.push({
                  id: element.id,
                  account_id: element.account_id,
                  bot_token: element.bot_token,
                  bot_id: element.bot_id,
                  bot_status: element.bot_status,
                  bot_last_online: element.bot_last_online
                });
            })
          } 
          else if (resData.status == 440){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              localStorage.clear();
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.loading = false;
          this.disabled = false
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `${error}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
      });
    },
    updateBotStatus(value){
        this.$Progress.start();
        this.modalLoading = true
        var bodyFormData = new FormData();
        this.liveUpdateSeconds = 15;
        clearInterval(this.liveUpdateIntervalId);
        clearInterval(this.liveUpdateSecondsId);
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("botID", value.bot_id);
        bodyFormData.append("status", value.action);
        console.log(value)
        axios({
            method: "post",
            //url: appConfig.APIHostAdmin + 'controller/admin/',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            this.returnData = []
            if (resData.status == 200) {
              console.log(resData)
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
             
            this.modalLoading = false;
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: '1Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
        this.updateLiveUpdates();
    },
    liveUpdates() {
      this.liveUpdateIntervalId = setInterval(() => {
        this.getBankAccountList();
        this.liveUpdateSeconds = 15
        this.liveUpdateLoading = true;
      }, 16000);

      this.liveUpdateSecondsId = setInterval(() => {
        this.liveUpdateSeconds = this.liveUpdateSeconds-1
        if (this.liveUpdateSeconds<=0){
          this.liveUpdateSeconds = 0
        }
      }, 1000);
    },
    updateLiveUpdates(){
      this.loading = true;
      if (this.liveUpdate){
        this.liveUpdates();
      }else{
        clearInterval(this.liveUpdateIntervalId);
        clearInterval(this.liveUpdateSecondsId);
      }
      console.log(this.liveUpdateIntervalId);
      this.getBankAccountList();
    },
    visibleFields() {
      return this.fields.filter(field => {
      // Include all fields except the "Action" column if the condition is true
      return field.key !== 'action' || this.create_modify_permission;
    });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage2 = 1;
    },
    reset() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.selectedBankAccount = []
      this.currentPage2 = 1
      this.liveUpdateSeconds = 15
      clearInterval(this.liveUpdateIntervalId);
      clearInterval(this.liveUpdateSecondsId);
    },
    inputNumberOnly(event, parentModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this.formSuccessData[parentModel] = numericValue;
    },
    inputNumberOnlyV2(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][index][childModel] = numericValue
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
          const permission = data.modules.filter(e => e.name==='administrator')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
            console.log(this.create_modify_permission)
          } else {
            console.log('Module with name "administrator" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>
